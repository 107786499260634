import React from 'react'
import ReactDOM from 'react-dom'

import { config } from 'dotenv'

config()

import App from "./App";

console.log(process.env.REACT_APP_GMS_ADMIN_URL)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)
