// Write your code here
import './index.css'

const AppItem = props => {
  const {appDetails} = props
  const {appName, imageUrl, url} = appDetails

  const handleClick = e => {
    e.preventDefault()
    window.open(url, '_blank')
  }

  return (
    <li className="app-item">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <img src={imageUrl} alt={appName} className="app-logo" />
        <p className="app-name">{appName}</p>
      </a>
    </li>
  )
}

export default AppItem
