import {Component} from 'react'
import './index.css'
import TabItem from '../TabItem'
import AppItem from '../AppItem'

const tabsList = [
  {tabId: 'CMS', displayText: 'Marketing'},
  {tabId: 'RMS', displayText: 'Sales'},
  {tabId: 'GMS', displayText: 'Guest Experience'},
  {tabId: 'PMS', displayText: 'Property Operations'},
]
const env = process.env;
const appsList = [
  {
    appId: 0,
    appName: 'CMS Admin',
    imageUrl: 'https://d31za8na64dkj7.cloudfront.net/gms_5bc007cc0d.png',
    category: 'CMS',
    url: env.REACT_APP_CMS_ADMIN_URL,
  },
  {
    appId: 1,
    appName: 'Strapi CMS Admin',
    imageUrl:
      'https://cdn-1.webcatalog.io/catalog/strapi/strapi-icon-filled-256.webp?v=1675594761771',
    category: 'CMS',
    url: env.REACT_APP_WEB_ADMIN_URL,
  },

  {
    appId: 10,
    appName: 'Staah',
    imageUrl:
      'https://desk.zoho.com/portal/api/kbCategory/109504000070394595/logo/7268199000001119846?orgId=60775192',
    category: 'RMS',
    url: env.REACT_APP_RMS_STAAH_URL,
  },
  {
    appId: 21,
    appName: 'GMS Admin',
    imageUrl: 'https://d31za8na64dkj7.cloudfront.net/gms_5bc007cc0d.png',
    category: 'GMS',
    url: env.REACT_APP_GMS_ADMIN_URL,
  },
  {
    appId: 30,
    appName: 'PMS Admin',
    imageUrl: 'https://d31za8na64dkj7.cloudfront.net/pms_359fc31e81.png',
    category: 'PMS',
    url: env.REACT_APP_PMS_ADMIN_URL,
  },
]

// Write your code here
class AppStore extends Component {
  state = {activeTabId: tabsList[0].tabId, searchInput: ''}

  getFilteredApps = () => {
    const {activeTabId} = this.state
    const filteredApps = appsList.filter(
      eachApp => eachApp.category === activeTabId,
    )
    return filteredApps
  }

  onChangeSearchInput = event => {
    this.setState({searchInput: event.target.value})
  }

  getSearchResults = () => {
    const {searchInput, activeTabId} = this.state
    const searchResults = appsList.filter(
      eachItem =>
        eachItem.appName.toLowerCase().includes(searchInput.toLowerCase()) &&
        eachItem.category === activeTabId,
    )
    return searchResults
  }

  onClickTabUpdate = value => {
    this.setState({activeTabId: value})
  }

  render() {
    const {activeTabId, searchInput} = this.state
    const filteredAppsList = this.getFilteredApps()
    const searchedAppsList = this.getSearchResults()

    return (
      <div className="bg-container">
        <h1 className="heading">Elivaas App Store</h1>
        <div className="search-container">
          <input
            type="search"
            className="search"
            onChange={this.onChangeSearchInput}
          />
          <img
            src="https://assets.ccbp.in/frontend/react-js/app-store/app-store-search-img.png"
            alt="search icon"
            className="search-icon"
          />
        </div>
        <ul className="tabs-container">
          {tabsList.map(eachItem => (
            <TabItem
              tabDetails={eachItem}
              key={eachItem.tabId}
              onClickTabUpdate={this.onClickTabUpdate}
              isActive={eachItem.tabId === activeTabId}
            />
          ))}
        </ul>
        <ul className="apps-container">
          {searchedAppsList.map(eachItem => (
            <AppItem appDetails={eachItem} key={eachItem.appId} />
          ))}
        </ul>
      </div>
    )
  }
}

export default AppStore
